.parallax > use{
  opacity: 0.1;
  animation:move-forever 12s linear infinite;
  &:nth-child(1){animation-delay:-2s;}
  &:nth-child(2){animation-delay:-2s; animation-duration:10s}
  &:nth-child(3){animation-delay:-4s; animation-duration:13s}
}

@keyframes move-forever{
  0%{transform: translate(-90px , 0%)}
  100%{transform: translate(85px , 0%)} 
}

.editorial {
  display: block;
  width: 100%;
  height: 70vh;
  max-height: 100vh;
  margin: 0;
}



/*prevent many large-by-comparison ripples by shrinking the height*/
/* @media (max-width:50em){
  .content h1{font-size: 12vmax}
  .editorial{height:17vw;}
} */
