:root {
  /* background colors */
  --background-primary: #121318;
  /* --background-dark: #000; */
  --background-dark: #0D0F12;

  /* --fill-primary: #1f2bab; */
  --fill-primary: #5A4CCB;
  --fill-secondary: #252b49;

  /* Text colors */
  --text-primary: #ffffff;
  --text-secondary: #cad1de;

  /* Brand colors */
  --sui-sea: #4DA2FF;
}
